<template>
    <div class="form">
        <div class="form-group">
            <div class="input-container">
                <label for="name">Naam</label>
                <input required type="text" name="name" v-model="formData.name">
            </div>
            <div class="input-container">
                <label for="role">Ploegleider</label>
                <multiselect
                    v-model="formData.teamLeader"
                    :options="teamLeaders"
                    label="full_name"
                    track-by="full_name"
                    :clearOnSelect="false"
                    :preserveSearch="true"
                    :placeholder="'Selecteer medewerker'"
                    :open-direction="'bottom'"
                    select-label=""
                    deselect-label=""
                    selected-label=""
                    :required="true"
                >
                    <template slot="tag" slot-scope="props">
                        <span v-text="props.option.full_name + ', '"></span>
                    </template>
                    <template slot="option" slot-scope="props">
                        <div class="option__desc">
                            <span class="option__title">{{ props.option.full_name }}</span>
                            <img class="selected" src="/images/check.svg" alt="selected">
                        </div>
                    </template>
                    <span slot="noResult">Geen resultaten</span>
                </multiselect>
            </div>
            <div class="input-container">
                <label for="role">Ploegleden</label>
                <multiselect
                    v-model="formData.employees"
                    :options="employees"
                    label="first_name"
                    track-by="first_name"
                    :clearOnSelect="false"
                    :preserveSearch="true"
                    :placeholder="'Selecteer medewerker'"
                    :open-direction="'bottom'"
                    select-label=""
                    deselect-label=""
                    selected-label=""
                    :close-on-select="false"
                    :multiple="true"
                    :required="true"
                >
                    <template slot="tag" slot-scope="props">
                        <span v-text="props.option.first_name + ', '"></span>
                    </template>
                    <template slot="option" slot-scope="props">
                        <div class="option__desc">
                            <span class="option__title">{{ props.option.first_name }}</span>
                            <img class="selected" src="/images/check.svg" alt="selected">
                        </div>
                    </template>
                    <span slot="noResult">Geen resultaten</span>
                </multiselect>
            </div>
        </div>
        <div class="submit-container">
            <button @click="submit" class="submit-button" v-text="buttonText">
            </button>
        </div>
    </div>
</template>
<script>
    import Multiselect from 'vue-multiselect';

    export default {
        name: 'team-sidebar-form',
        components: {
            Multiselect,
        },
        props: {
            team: {
                required: false,
            },
            teamLeaders: {
                required: true,
            },
            employees: {
                required: true,
            },
        },
        data() {
            return {
                buttonText: '',
                formData: {
                    name: '',
                    teamLeader: null,
                    employees: []
                },
            }
        },
        methods: {
            submit() {
                let type = 'store';
                if (this.team) {
                    type = this.team.id + '/update'
                }
                axios.post('/admin/teams/' + type,
                    this.formData
                ).then(response => {
                        window.location.href = '/admin/teams?success=Succesvol opgeslagen'
                }).catch((error) => {
                    Object.keys(error.response.data.errors).map((key) => {
                        this.$flashMessage(error.response.data.errors[key][0], 500000, 'error')
                    });
                })
            },
            setParams() {
                this.buttonText = 'Ploeg toevoegen';
                if (this.team) {
                    this.buttonText = 'Wijzigingen opslaan';
                    this.formData.name = this.team.name;
                    this.formData.employees = this.team.employees;
                    this.formData.teamLeader = this.team.team_leader[0];
                }
            },
        },
        mounted() {
            this.setParams();
        }
    }
</script>
