import Axios from "axios";
import Vue from 'vue';

require('./bootstrap');
import TeamSidebarForm from "./components/TeamSidebarForm";
import messages from './plugins/messages';

window.Vue = Vue;

Vue.prototype.$axios = Axios;
Vue.component('team-sidebar-form', TeamSidebarForm);
Vue.use(messages);

new Vue({
    el: '#app',
});
